import create from 'zustand';
import { AsyncStatus } from './types';

export interface AppState {
  status: AsyncStatus;
}

export const useAppStateStore = create<AppState>((set) => ({
  status: 'idle',
}));
