import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PATIENT_API_BASE_URL } from '../common/constants';
import { DeepLinkingConfig } from 'assets/core/deep-linking';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { DefaultApi as PatientServiceClient } from '@digitalpharmacist/patient-service-client-axios';

export interface IPatientService {
  healthCheck: () => Promise<any>;
}

export class PatientService extends BaseApiService implements IPatientService {
  private patientServiceClient: PatientServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.patientServiceClient = new PatientServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async healthCheck(): Promise<any> {
    const response = await this.patientServiceClient.appGetHealthCheck();
    return response.data;
  }
}

export default new PatientService(
  PATIENT_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  false,
);
