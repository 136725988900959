import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import PatientService from '../../api/PatientService';
import { logError } from 'assets/logging/logger';
import { useAppStateStore } from '../../store/app-store';

export const Dashboard: FunctionComponent = () => {
  const styles = useStyles();
  const [healthCheckResponse, setHealthCheckResponse] = React.useState<any>();
  const { status } = useAppStateStore();

  const handleHealthCheck = async () => {
    useAppStateStore.setState({ status: 'loading' });
    try {
      await PatientService.healthCheck();
      useAppStateStore.setState({ status: 'idle' });
      setHealthCheckResponse('Patient Service is up and running!');
    } catch (error) {
      useAppStateStore.setState({ status: 'error' });
      setHealthCheckResponse('Patient Service is down!');
      logError(new Error('Patient Service is down!'));
    }
  };

  return (
    <View style={styles.container}>
      <Button
        hierarchy={'primary'}
        logger={{
          id: 'admin-tools-patient-service-health-check',
          data: undefined,
        }}
        onPress={handleHealthCheck}
        fixedWidth
        style={styles.button}
      >
        Patient Service Health Check
      </Button>
      {status !== 'loading' && status !== 'error' && (
        <Text>{healthCheckResponse}</Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(2),
    flex: 1,
    backgroundColor: theme.palette.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginBottom: theme.getSpacing(2),
  },
}));

export default Dashboard;
