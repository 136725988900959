import React from 'react';
import RootNavigation from './modules/navigation/RootNavigation';

const App = () => {
  return (
    <>
      <RootNavigation />;
    </>
  );
};
export default App;
