import React, { FunctionComponent } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Dashboard from '../screens/dashboard/Dashboard';
import Header from '../components/header/Header';
import { NavigationProp } from '@react-navigation/native';
import ExampleScreen from '../screens/example/ExampleScreen';

const RootStack = createStackNavigator();

export const RootNavigation: FunctionComponent = () => {
  return (
    <NavigationContainer>
      <RootStack.Navigator
        screenOptions={{
          headerShown: true,
          header: () => <Header />,
        }}
      >
        <RootStack.Screen name="Dashboard" component={Dashboard} />
        <RootStack.Screen name="Example" component={ExampleScreen} />
      </RootStack.Navigator>
    </NavigationContainer>
  );
};

export type RootNavigationParamList = {
  Dashboard: undefined;
  Example: undefined;
};

export type RootNavigationProps = NavigationProp<RootNavigationParamList>;

export default RootNavigation;
