import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { useNavigation } from '@react-navigation/native';
import { makeStyles } from 'assets/theme';
import {
  RootNavigationParamList,
  RootNavigationProps,
} from '../../navigation/RootNavigation';

const Header = () => {
  const navigation = useNavigation<RootNavigationProps>();
  const styles = useStyles();

  const handleNavigation = (screen: keyof RootNavigationParamList) => {
    navigation.navigate(screen);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          handleNavigation('Dashboard');
        }}
        style={styles.item}
      >
        <Image
          source={require('../../../assets/lumistry-logo.png')}
          style={styles.logo}
        />
      </TouchableOpacity>
      <View style={styles.itemsContainer}>
        <TouchableOpacity
          onPress={() => {
            handleNavigation('Dashboard');
          }}
          style={styles.item}
        >
          <Text style={styles.itemText}>Dashboard</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            handleNavigation('Example');
          }}
          style={styles.item}
        >
          <Text style={styles.itemText}>ExampleScreen</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    backgroundColor: '#1764C0',
    height: 60,
    alignItems: 'center',
    paddingHorizontal: theme.getSpacing(2),
  },
  logo: {
    marginLeft: 100,
    width: 140,
    height: 40,
  },
  itemsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 120,
  },
  item: {
    paddingHorizontal: theme.getSpacing(1),
  },
  itemText: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

export default Header;
