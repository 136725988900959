import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';

export const ExampleScreen: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Text>Example Screen</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(2),
    flex: 1,
    backgroundColor: theme.palette.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginBottom: theme.getSpacing(2),
  },
}));

export default ExampleScreen;
